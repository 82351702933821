import React from 'react';

const SimpleHeader = ({profile}) => {
    return (
        <div className='p-5 bg-gray-100 flex justify-between'>
            <h2 className='font-bold  self-center'>Hi! {profile.name}</h2>
            <h2 className='font-light self-center'>{profile.code}</h2>
        </div>
    );
}

export default SimpleHeader;
