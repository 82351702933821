import { HttpPost } from 'endpoint';

const ADMIN_ENDPOINT = process.env.REACT_APP_ADMIN_ENDPOINT
const PARTNER_ENDPOINT = process.env.REACT_APP_PARTNER_ENDPOINT

export async function login(user) {
    const url = `${PARTNER_ENDPOINT}api/login-partner`
    return HttpPost(url, {...user}, "")
}

export async function managerLogin(user) {
    const url = `${PARTNER_ENDPOINT}api/login-manager`
    return HttpPost(url, {...user}, "")
}
