import { useState } from "react";
import { useEffect } from "react";
import { getAllTransactions } from "../api";
import Withdraw from "../components/Withdraw";

export default function Index() {
    const [balance, setBalance] = useState(0)
    const [transactions, setTransactions] = useState([])
    const [withdrawShow, setWithdrawShow] = useState(false)
    const [filter, setFilter] = useState({
        content: "",
        type: "",
        ref: "",
        start: 962641361,
        end: Date.now(),
    });
    const profile = JSON.parse(localStorage.getItem("token"))


    useEffect(() => {
        getAllTransactions(profile.walletId)
            .then((response) => {
                var { data } = response.data
                setTransactions(data)
            })
    }, []);

    useEffect(() => {
        evaluateBalance()
    }, [transactions]);

    let evaluateBalance = () => {
        let total = 0
        transactions.forEach(v => {
            total += v["amount"]
        });
        setBalance(total)
    }

    useEffect(() => {
        let _transactions = [...transactions].filter((x) => x.description.includes(filter.content))
        _transactions = [..._transactions].sort(function (a, b) {
          return a.created_at - b.created_at;
        });
      //  setTransactions(_transactions)
    }, [filter])
    
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div>
                    <div class="flex lg:flex-row flex-col items-center p-5 text-sm">
                        <div class="flex   flex-1">
                            <select
                                onChange={(e) => setFilter({ ...filter, content: e.target.value })}
                                className="p-1 px-2 border  " >
                                <option value="">Choose Category</option>
                                <option value="activation">Activation Bonus</option>
                                <option value="refer">EARNINGS </option>
                                <option value="percentage">PARTNERS NET</option>
                            </select>
                        </div>

                        <div class=" flex-col lg:py-0 py-4 hidden">
                            <label for="email1" class=" "> Start Date </label>
                            <input
                                type="date"
                                onChange={(e) => {
                                    let starting = 962641361;
                                    if (e.target.value) {
                                        starting = new Date(e.target.value).setHours(0, 0, 0, 1);
                                    }
                                    setFilter({
                                        ...filter,
                                        start: starting,
                                    });
                                }}
                                id="email1"
                                className="p-1 px-2 border"
                                placeholder="example@example.com"
                            />
                        </div>
                        <div class="flex flex-col lg:py-0 py-4 hidden">
                            <label for="email1" class=" "   >  End Date  </label>
                            <input type="date"
                                onChange={(e) => {
                                    let ending = Date.now();
                                    if (e.target.value) {
                                        ending = new Date(e.target.value).setHours(23, 59, 59, 999);
                                    }
                                    setFilter({ ...filter, end: ending });
                                }}
                                id="email1"
                                class="p-1 px-2 border"
                            />
                        </div>
                    </div>
                </div>

                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <h4>Actions</h4>
                    <div className="mt-6 lg:mt-0">
                        <button className="transition duration-150 ease-in-out  focus:outline-none border bg-gray-800 rounded text-white px-8 py-2 text-sm">
                            Available balance: {balance}
                        </button>
                        <button className="transition duration-150 ease-in-out  focus:outline-none border bg-blue-500 rounded text-white px-8 py-2 text-sm"
                        onClick={()=> setWithdrawShow(!withdrawShow)}
                        >
                            Withdraw to BANK
                        </button>
                    </div>
                </div>
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
                        <TableData transactions={transactions} filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <Withdraw isOpen={withdrawShow} setIsOpen={setWithdrawShow}/>
        </div>
    );
}

function TableData({ transactions, filter, setFilter }) {
    const [entry, setEntry] = useState([...transactions]);

    useEffect(() => {
        const showing = transactions.filter(function (entry) {
            return (
                (entry.description.toLowerCase().includes(filter.content) || entry.reference.toLowerCase().includes(filter.content)) &&

                isBetween(
                    new Date(entry.created_at * 1000),
                    new Date(filter.start),
                    new Date(filter.end)
                )
            );
        });

        setEntry([...showing]);
        return () => { };
    }, [transactions, filter]);

    const isBetween = (date, min, max) =>
        date.getTime() >= min.getTime() && date.getTime() <= max.getTime();

    return (
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className='w-full'>
                    <th scope="col" class="py-3 px-6"> Date</th>
                    <th scope="col" class="py-3 px-6">  Amount </th>
                    <th scope="col" class="py-3 px-6"> Transaction Reference </th>
                    <th scope="col" class="py-3 px-6">  Description </th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {entry.map((game, index) => (
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                {new Date(Number(game.created_at * 1000)).toLocaleDateString("en-US")}
                            </a>
                        </th>
                        <td className=" ">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> {game.amount}  </span>
                        </td>
                        <td className=" ">  <h6>{game.reference}</h6>  </td>
                        <td className=""> <h6>{game.description}</h6>  </td>
                    </tr>
                ))}
            </tbody>
        </table>)

}
