import { getEliestTransactions } from 'modules/wallet/api';
import React, { useEffect, useState } from 'react'
import { Pages } from './GiveAways';

function GamesWins(props) {

    let timeInUnix = (hour, minute, sec) => {
        var time = new Date;
        time.setHours(hour);
        time.setMinutes(minute);
        time.setSeconds(sec);
        var timestamp = Math.floor(time / 1000);
        return timestamp
    }

    const handleTimelineChange = (e, hour, min, sec) => {
        let init = 962641361;
        if (e.target.value) {
            init = new Date(e.target.value).setHours(hour, min, sec, 59);
        }
        let copyFilter = { ...filter }
        copyFilter[e.target.name] = Math.floor(init / 1000)
        setFilter(copyFilter);
    }

    const countOptions = [10, 20, 50, 100]
    const [filter, setFilter] = useState({
        content: "",
        type: "GAME PLAY FEE",
        ref: "",
        class: "DEBIT",
        from: timeInUnix(0, 0, 0),
        to: timeInUnix(23, 59, 59)
    });

    function preparePages(transactions, presentPage) {
        let _transactions = [...transactions].filter((x => x["description"].includes(filter.type)))
        _transactions = [..._transactions].filter((x => x["class"].includes(filter.class)))
        _transactions = [..._transactions].filter(() => true)
        _transactions = [..._transactions].sort(function (a, b) {
            return b.created_at - a.created_at;
        });
        setTotal(_transactions.length)
        var start = ((presentPage - 1) * pageCount);
        var end = start + pageCount;
        const listPage = _transactions.slice(start, end);
        return listPage
    }

    useEffect(() => {
        if (page != 1) {
            setPage(1)
        }

    }, [filter])

    function handlePageChange(p) {
        setPage(p + 1)
    }
    function handleCountChange(p) {
        setPageCount(p)
    }

    const [transactions, setTransactions] = useState([])
    const [pageTransactions, setPageTransactions] = useState([]);
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(countOptions[0])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        loadTransactions()
    }, [])

    function loadTransactions() {
        setLoading(true)
        getEliestTransactions(filter.from, filter.to).then(res => {
            var { data } = res.data
            console.log(data)
            let _transactions = [...data].sort(function (a, b) {
                return a.created_at - b.created_at;
            });
            setTransactions(_transactions)
            setLoading(false)

        }).catch(() => {
            alert("error")
            setLoading(false)

        })
    }

    useEffect(() => {
        if (transactions.length > 0) {
            const setTOshow = preparePages(transactions, page)
            setPageTransactions(setTOshow)
        }

    }, [page, pageCount, transactions, filter])

    return (
        <div class="overflow-x-auto relative">

            <div className="my-2 flex flex-col md:flex-row">
                <div className='text-xs w-full flex-1 text-gray-600 px-5'>
                    <h2 className="font-bold text-lg border-b">
                        Games and Wins
                    </h2>
                    <p>Fr: {new Date(filter.from * 1000).toLocaleString()}</p>
                    <p>To: {new Date(filter.to * 1000).toLocaleString()}</p>
                </div>
                <div className='text-xs w-full flex-1 text-gray-600 px-5'>
                    <p>From: </p>
                    <input type="date" name="from" min={"2022-10-10"} onChange={(e) => handleTimelineChange(e, 0, 0, 0)} class=" p-1 px-2 rounded border w-full" />
                    <p>To: </p>
                    <input type="date"  min={"2022-10-10"}  onChange={(e) => handleTimelineChange(e, 23, 59, 59)} name="to" class=" p-1 px-2  rounded border w-full" />
                    <div className="flex my-1">
                        <button className="px-5 py-1 bg-gray-800 text-gray-100 rounded" onClick={() => loadTransactions()}>Search</button>
                    </div>
                </div>
            </div>

            <div>
                {transactions.length > 0 && <Pages
                    pageCount={pageCount}
                    current={page}
                    counts={countOptions}
                    handleOptChange={(cnt) => handleCountChange(cnt)}
                    handlePageChange={(p) => handlePageChange(p)}
                    total={total}
                />
                }
            </div>

            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-3 px-6">  DATE  </th>
                        <th scope="col" class="py-3 px-6">  REFERENCE  </th>
                        <th scope="col" class="py-3 px-6">  AMOUNT  </th>
                        <th scope="col" class="py-3 px-6">  DESCRIPTION  </th>
                        <th scope="col" class="py-3 px-6">  ACCOUNT  </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {loading && <tr className='bg-gray-800 text-gray-50 text-center'>
                        <td ></td>
                        <td colSpan={2} rowSpan={2} >loading ...</td>
                        <td></td>
                        <td colSpan={2} rowSpan={2} >loading ...</td>
                    </tr>}
                    {pageTransactions.map(transaction => <TransactionItem key={transaction.id} transaction={transaction} />)}
                </tbody>
            </table>

        </div>
    )
}

function TransactionItem({ transaction }) {
    return <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {new Date(transaction.created_at * 1000).toLocaleString()}
        </th>
        <td>{transaction.reference}</td>
        <td>{transaction.amount}</td>
        <td>{transaction.description} </td>
        <td>{transaction.account}</td>
    </tr>
}

export default GamesWins

