import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { findTransactions } from '../api';

function AWallet() {

    const location = useLocation()
    const { wallet } = location.state

    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);

    let loadTransactions = async (account) => {
        setIsLoading(true)
        try {
            let response = await findTransactions(wallet.id)
            response = response.data
            if (response.status !== "SUCCESS") {
                alert("Error loading Data")
            } else {
                let _transactions = response.data.transactions
                _transactions = [..._transactions].sort(function (a, b) {
                    return a.created_at - b.created_at;
                });
                setTransactions(_transactions)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (wallet) {
            loadTransactions()
        }
        return () => { };
    }, [wallet]);


    return (
        <>
            <div className="flex flex-col items-center justify-between w-full">
                <div className="flex flex-col  w-full items-start lg:items-center rounded  my-3">
                {isLoading &&  <p className='text-center text-green-800 animate-pulse'>loading...</p>}

                    <table className="divide-y divide-gray-200 mx-auto overflow-x-auto">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Title </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Amount </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">  Reference </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" >Date  </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {transactions.map(transaction => <TransactionItem transaction={transaction} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}


function TransactionItem({ transaction }) {
    return (
        <>
            <tr className={"" + (transaction.class == "CREDIT" ? "bg-green-50" : "bg-red-50")}>
                <td className="px-6 py-4 whitespace-nowrap">
                    <p className="text-sm text-gray-500"> {transaction.description} </p>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">₦{transaction.amount}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{transaction.reference}</div>
                </td>
               
                <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                    {new Date(transaction.created_at * 1000).toLocaleDateString()}
                </td>
            </tr>
        </>
    );
}

export default AWallet
