import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { verifyNuban, withdrawNuban } from '../api';

export default function Withdraw({ isOpen, setIsOpen }) {

    function closeModal() {
        setIsOpen(false)
    }
    const [withdrawal, setWithdrawal] = useState({
        "recipient": "",
        "amount": 0.0,
        "bank": "",
        "nuban": "",
        "name": ""
    });
    const [isVerifyingNuban, setIsVerifyingNuban] = useState(false);
    const [transferResponse, setTransferResponse] = useState("");
    const profile = JSON.parse(localStorage.getItem("token"))

    let findVerifyWithdrawal = async () => {
        setIsVerifyingNuban(true)
        try {
            let response = await verifyNuban({ "nuban": withdrawal.nuban, "bank": withdrawal.bank })
            var { data } = response.data
            if (response.data.status !== "SUCCESS") {
                alert(JSON.stringify(data))
                setTransferResponse(response.data.message)

            } else {
                setWithdrawal({ ...withdrawal, recipient: data.recipient, name: data.name })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsVerifyingNuban(false)
        }
    };

    let makeWithdrawal = async () => {
        setIsVerifyingNuban(true)
        try {
            let response = await withdrawNuban({ "amount": withdrawal.amount.toString(), "recipient": withdrawal.recipient, wallet: profile.walletId })
            if (response.status !== "SUCCESS") {
                setTransferResponse(response.message)

            } else {
                setTransferResponse("Success!!! Your transfer is been processed.")
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsVerifyingNuban(false)
        }
    };


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Process withdrawal
                                    </Dialog.Title>
                                    <div className="flex justify-between w-full sm:w-full my-5 px-6 ">
                                        <div className="flex flex-col w-full">
                                            <div className="flex space-x-1 w-full">
                                                <select className="border-0 p-2 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" onChange={(e) => setWithdrawal({ ...withdrawal, bank: e.target.value })}>
                                                    <option defaultValue="">Bank</option>
                                                    <option value="050">Ecobank</option>
                                                    <option value="011">First Bank</option>
                                                    <option value="058">Guaranty Trust Bank (GTB)</option>
                                                    <option value="030">Heritage Bank</option>
                                                    <option value="082">Keystone Bank</option>
                                                    <option value="221">Stanbic IBTC Bank</option>
                                                    <option value="232">Sterling Bank</option>
                                                    <option value="215">Unity Bank</option>
                                                    <option value="035">Wema Bank</option>
                                                    <option value="057">Zenith Bank</option>

                                                </select>

                                                <input
                                                    placeholder="Account Number"
                                                    value={withdrawal.nuban}
                                                    onChange={(e) => setWithdrawal({ ...withdrawal, nuban: e.target.value })}
                                                    className="border-0 p-2 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                />

                                                <button className="p-2 bg-gray-800 text-gray-100 rounded text-sm shadow" onClick={() => findVerifyWithdrawal()}>

                                                    Verify</button>
                                            </div>
                                            <div className="flex space-x-1 w-full my-2" >
                                                <input
                                                    placeholder="Account Name"
                                                    value={withdrawal.name}
                                                    disabled
                                                    className="border-0 p-2 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                />
                                                <input
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    value={withdrawal.amount}
                                                    onChange={(e) => setWithdrawal({ ...withdrawal, amount: Number(e.target.value) })}
                                                    className="border-0 p-2 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                />
                                                <button disabled={withdrawal.recipient === ""} className="p-2 bg-gray-800 text-gray-100 rounded text-sm shadow" onClick={() => makeWithdrawal()}>Continue</button>
                                            </div>
                                            {transferResponse != "" && <p className="text-xs text-red-800 text-center mt-2">{transferResponse}</p>}

                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium"
                                            onClick={closeModal}
                                        >
                                           Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
