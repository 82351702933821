import axios from "axios";

const endpoint = process.env.REACT_APP_PARTNER_ENDPOINT
const TRANSACTION_ENDPOINT = process.env.REACT_APP_TRANSACTION_ENDPOINT

const http = axios.create({
    baseURL: endpoint,
    headers: {
        "Content-type": "application/json"
    }
});

export function createNewManager(data) {
    return http.post(`api/manager`, data);
}

export function getMyManagers(pid) {
    return http.get(`api/partner-managers?partner=${pid}`);
}

export function getMyManager(code) {
    return http.get(`api/manager?code=${code}`);
}

export function getMangersAgents(code) {
    return http.get(`api/manager-agents?code=${code}`);
}

export function deleteMangersAgents(code) {
    return http.delete(`api/manager?code=${code}`);
}

export async function findTransactions(account) {
    return http.get(`${TRANSACTION_ENDPOINT}get-transactions?account=${account}`);
}