import React, { useState } from 'react'
import { createNewManager } from '../api';

function AddNew() {

    const profile = JSON.parse(localStorage.getItem("token"))

    const [createPayload, setCreatePayload] = useState({
        "name": "",
        "email": "",
        "partnerId": profile.id,
        "password": ""
    });
    const [response, setResponse] = useState("")

    function handleCreateAgent(e) {
        if (createPayload.email == "" || createPayload.password == "" || createPayload.name == "") {
            return
        }
        e.preventDefault()
        createNewManager(createPayload).then(res => {
            const { data } = res
            setResponse(data.message)
        }).catch(e => {
            const { data } = e.response
            setResponse(data.message)
        }).finally(() => {
            window.location.reload();
        })
    }

    return (
        <div>
            <form className='shadow rounded-lg p-5 space-y-5'>
                <div className='flex flex-row pt-5'>
                    <h4 className='font-bold'>Add A new Manager to your team</h4>
                </div>
                <div className='flex flex-row space-x-5'>
                    <div className='flex-1 border rounded w-full'>
                        <input placeholder='manager name' className='outline-none bg-gray-100 w-full px-2 py-1' value={createPayload.name} onChange={(e) => setCreatePayload({ ...createPayload, name: e.target.value })} />
                    </div>
                    <div className='flex-1 border rounded w-full'>
                        <input placeholder='email' className='outline-none bg-gray-100 w-full px-2 py-1' value={createPayload.email} onChange={(e) => setCreatePayload({ ...createPayload, email: e.target.value })} />
                    </div>
                </div>
                <div className='flex flex-row space-x-5'>
                    <div className='flex-1 border rounded w-full'>
                        <input placeholder='password' className='outline-none bg-gray-100 w-full px-2 py-1' value={createPayload.password} onChange={(e) => setCreatePayload({ ...createPayload, password: e.target.value })} />
                    </div>
                    <div className='flex-1 border rounded w-full'>
                    </div>
                </div>
                <div className='flex flex-row '>
                    <button className='py-1 px-5 bg-green-900 text-white rounded-lg' onClick={(e) => handleCreateAgent(e)}>Save</button>
                </div>
                <div className='flex flex-row '>
                    {response && <p>{response}</p>}
                </div>
            </form>
        </div>
    )
}

export default AddNew