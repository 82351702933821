import React, { useEffect, useState } from 'react'
import { getMangersAgents, getMyManager } from '../api'
import NaijaStates from "naija-state-local-government";
import { Link, useParams } from 'react-router-dom';

function Manager() {
    const countOptions = [10, 20, 50, 100]
    const initFilter = {
        name: "",
        ref_code: "",
        email: "",
        state: "",
        lg: "",
    }
    const [pagAgents, setPageAgents] = useState([]);
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(countOptions[0])
    const [total, setTotal] = useState(0)
    const [agents, setAgents] = useState([]);
    const [mgt, setMgt] = useState({});
    const { code } = useParams();

    const ngStates = NaijaStates.states();

    const [error, setError] = useState("");
    const [filter, setFilter] = useState(initFilter);

    useEffect(() => {

        getMyManager(code).then((res) => {
            const { data } = res
            const { manager } = data.data
            setMgt(manager)
        })
        getMangersAgents(code).then(res => {
            const { data } = res
            var _agents = data.data
            _agents = [..._agents].sort(function (a, b) {
                return a.created_at - b.created_at;
            });
            setAgents(_agents)
        })
    }, [])

    useEffect(() => {
        if (agents.length > 0) {
            const setTOshow = preparePages(agents, page)
            setPageAgents(setTOshow)
        }

    }, [page, pageCount, agents, filter])

    function preparePages(agents, presentPage) {
        let _agents = [...agents].filter(
            e => e.firstname.toLowerCase().includes(filter.name.toLowerCase()) ||
                e.lastname.toLowerCase().includes(filter.name.toLowerCase()) ||
                e.phone.toLowerCase().includes(filter.name.toLowerCase()) ||
                e.address.toLowerCase().includes(filter.name.toLowerCase()) ||
                e.refcode.toLowerCase().includes(filter.name.toLowerCase())
        )

        _agents = [..._agents].filter(
            e => e.state.toLowerCase().includes(filter.state.toLowerCase()) &&
                e.lg.toLowerCase().includes(filter.lg.toLowerCase())
        )
        _agents = [..._agents].sort(function (a, b) {
            return a.created_at - b.created_at;
        });
        setTotal(_agents.length)
        var start = ((presentPage - 1) * pageCount);
        var end = start + pageCount;
        const listPage = _agents.slice(start, end);
        return listPage
    }

    function handlePageChange(p) {
        setPage(p + 1)
    }
    function handleCountChange(p) {
        setPageCount(p)
    }

    return (
        <div className='space-y-5'>

            <div className='flex justify-between px-5'>

            </div>
            <div className='p-5 bg-gray-50'>
                <h1 className='text-sm '>{mgt.name} has {agents.length} agents</h1>
                <div>

                <div>

{agents.length > 0 && <Pages
  pageCount={pageCount}
  current={page}
  counts={countOptions}
  handleOptChange={(cnt) => handleCountChange(cnt)}
  handlePageChange={(p) => handlePageChange(p)}
  total={total}
/>
}
</div>


                    <table class="table-auto w-full text-center mt-1">
                        <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                            <tr>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold "></div>
                                </th> <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold ">Name</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold">Activated</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold">Joined</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold "></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-sm divide-y divide-gray-100">
                            {pagAgents.map((agent, i) => {
                                return <AgentItem key={i} index={i} agent={agent} />;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Manager

function Pages({ current, pageCount, handlePageChange, counts, handleOptChange, total }) {
    return (
        <div>
            <div className="block text-sm pt-10 pb-10 overflow-scroll">
                {[...Array(Math.ceil(total / pageCount)).keys()].map((k, i) => <span className={`cursor-pointer rounded-lg px-3 p-2 mx-2 bg-gray-100  ${k + 1 === current && " bg-green-400 text-white"}`} onClick={() => { handlePageChange(k) }} key={i}>{k + 1}</span>)}
            </div>
            <span>
                <select onChange={(e) => handleOptChange(e.target.value)} className="p-2 border rounded-lg m-1 w-20">
                    {counts.map(o => <option selected={o == pageCount} value={o}>{o}</option>)}
                </select>
            </span>
        </div>

    )
}


function AgentItem({ agent, token, index, addWallet }) {

    const [wallet, setWallet] = useState({ id: "" });


    const findFundedWallet = (agent) => {
        fetch(`${process.env.REACT_APP_TRANSACTION_ENDPOINT}find-wallet?owner=${agent}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + JSON.parse(token).access_token,
            },
        })
            .then(async (response) => {
                if (!response.ok) {
                    throw response;
                }
                let data = await response.json();
                setWallet(data.data.wallet)
                //addWallet(data.data.wallet)
            })
            .catch((e) => {
                return 0;
            });
    };

    useEffect(() => {
        findFundedWallet(agent.id)
        return () => { };
    }, []);

    return (
        <tr>
            <td class="p-2 whitespace-nowrap">
                <div class="">{index + 1}</div>
            </td> <td class="p-2 whitespace-nowrap">
                <div class="">{agent.lastname} {agent.firstname}</div>
            </td>
            <td class="p-2 whitespace-nowrap">
                <div class="">{agent.account_verified ? "YES" : "NO"}</div>
            </td>
            <td class="p-2 whitespace-nowrap">
                <div class="">{new Date(agent.created_at * 1000).toLocaleDateString()}</div>
            </td>
            <td>
                {wallet.id != "" && <Link to={{ pathname: "/agent/transactions", state: { wallet: wallet } }} className="border p-2 py-1 rounded">View Funding</Link>
                } </td>
        </tr>
    )
}