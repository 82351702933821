import axios from "axios";

const endpoint = process.env.REACT_APP_PARTNER_ENDPOINT

const http = axios.create({
    baseURL: endpoint,
    headers: {
        "Content-type": "application/json"
    }
});

export function getAllGiveAways() {
    return http.get(`api/giveaway-records`);
}

export function getAllWinners() {
    return http.get(`api/winners-records`);
}
