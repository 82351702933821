import axios from "axios";

const endpoint = process.env.REACT_APP_PARTNER_ENDPOINT
const AGENTS_ENDPOINT = process.env.REACT_APP_AGENTS_ENDPOINT

const http = axios.create({
    baseURL: endpoint,
    headers: {
        "Content-type": "application/json"
    }
});

export function getAllTransactions(wallet) {
    return http.get(`api/wallet-transactions?account=${wallet}`);
}

export function getEliestTransactions(from, to){
    return http.get(`api/eliest-transactions?from=${from}&to=${to}`);
}

export async function toBank(vtu) {
    return http.post(`api/vtu`)
}

export async function verifyNuban(data) {
    return http.post(`api/withdraw`, data)
}

export async function withdrawNuban(data) {
    return http.post(`api/pay`, data)
}
