import React from 'react';
import AddNew from '../components/AddNew';
import MangersList from '../components/MangersList';

const Index = () => {
    return (
        <div>
            <AddNew/>
            <MangersList/>
        </div>
    );
}

export default Index;
