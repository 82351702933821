import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getMangersAgents, getMyManagers } from '../api'

function MangersList() {

  const [managers, setManagers] = useState([])
  const profile = JSON.parse(localStorage.getItem("token"))

  useEffect(() => {
    getMyManager()
  }, [])


  function getMyManager(e) {
    getMyManagers(profile.id).then(res => {
      const { data } = res
      setManagers(data.data)
    }).catch(e => {
      //const { data } = e.response
      // setResponse(data.message)
    })
  }

  return (
    <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-10">
      <div className='p-5'>
        <p>You have {managers.length} manager(s)</p>
      </div>

    
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className='w-full'>
            <th scope="col" class="py-3 px-6">Code</th>
            <th scope="col" class="py-3 px-6">Name</th>
            <th scope="col" class="py-3 px-6">Email</th>
            <th scope="col" class="py-3 px-6 text-center"></th>

            <th scope="col" class="py-3 px-6 text-center">Downlines</th>
          </tr>
        </thead>
        <tbody>
          {managers.map(item => <ManagerItem item={item} />)}
        </tbody>
      </table>
    </div>
  )
}

export default MangersList

function ManagerItem({ item }) {
  const [downLines, setDownLines] = useState([])
  const [showDown, setShowDown] = useState(false)
  useEffect(() => {
    getMangersAgents(item.code).then(res => {
      const { data } = res
      setDownLines(data.data)
    })
  }, [])

  return (<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
      {item.code}</th>
    <td>{item.name}</td>
    <td>{item.email}</td>
    <td>
      <button onClick={async () => { await getMangersAgents(item.code); window.location.reload(); }} className='p-1 bg-green-200 rounded-lg text-xs'>Delete</button>
    </td>
    <td>
      <Link to={{ pathname: "/manager/transactions", state: { wallet: item.walletId } }} className="p-1 bg-red-200 rounded-lg text-xs">Funding</Link>
    </td>
    <td className='text-center'>Total: {downLines.length} Active: <span className='text-green-500'> {downLines.filter(e => e.account_verified).length}</span> Inactive: <span className='text-red-500'>{downLines.filter(e => !e.account_verified).length}</span></td>
    <td>
      <Link to={"/admin/mymanager/" + item.code}>Details</Link>
    </td>
    {showDown && downLines.map(item => <div className={`flex text-xs space-x-3 border-b border-gray-200 ${item.account_verified ? "text-green-500" : "text-red-500"}`}>
      <p> {item.firstname}</p>
      <p>{item.lastname}</p>
      <p> {item.phone}</p>
    </div>)}
  </tr>)
}
