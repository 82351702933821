import { useState } from "react";
import { useEffect } from "react";
import { getAllGiveAways, getAllTransactions } from "../api";

export default function Index() {

    const [giveAways, setGiveAways] = useState([])
    const countOptions = [10, 20, 50, 100]
    const initFilter = {
        name: "",
        ref_code: "",
        email: "",
        state: "",
        lg: "",
    }

    const [filter, setFilter] = useState(initFilter);

    const [pagUsers, setPageUsers] = useState([]);
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(countOptions[0])
    const [total, setTotal] = useState(0)

    useEffect(() => {

        getAllGiveAways().then(res => {
            var { data } = res.data
            console.log(data)
            let _giveAways = [...data].sort(function (a, b) {
                return a.created_at - b.created_at;
            });
            setGiveAways(_giveAways)
        }).catch(() => {
            alert("error")
        })
    }, [])

    useEffect(() => {
        if (giveAways.length > 0) {
            const setTOshow = preparePages(giveAways, page)
            setPageUsers(setTOshow)
        }

    }, [page, pageCount, giveAways, filter])

    function preparePages(giveAways, presentPage) {
        let _giveAways = [...giveAways].filter(() => true)

        _giveAways = [..._giveAways].filter(() => true)
        _giveAways = [..._giveAways].sort(function (a, b) {
            return a.created_at - b.created_at;
        });
        setTotal(_giveAways.length)
        var start = ((presentPage - 1) * pageCount);
        var end = start + pageCount;
        const listPage = _giveAways.slice(start, end);
        return listPage
    }

    useEffect(() => {
        if (page != 1) {
            setPage(1)
        }

    }, [filter])

    function handlePageChange(p) {
        setPage(p + 1)
    }
    function handleCountChange(p) {
        setPageCount(p)
    }

    return (
        <div class="overflow-x-auto relative">

            <div>

                {giveAways.length > 0 && <Pages
                    pageCount={pageCount}
                    current={page}
                    counts={countOptions}
                    handleOptChange={(cnt) => handleCountChange(cnt)}
                    handlePageChange={(p) => handlePageChange(p)}
                    total={total}
                />
                }
            </div>

            <div className="p-5 flex text-sm  bg-green-100 space-x-5">
                <h5 className="self-center font-bold">Summary</h5>
                <p className=" self-center">Records:  {giveAways.length} </p>
                
                {giveAways.length > 0 && <p className=" self-center">Total Payout: {[...giveAways].map(x => Number(x.amount)).reduce((a, b) => a + b)}</p>
                } </div>

            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-3 px-6">  ID  </th>
                        <th scope="col" class="py-3 px-6">   Amount  </th>
                        <th scope="col" class="py-3 px-6">   Phone  </th>
                        <th scope="col" class="py-3 px-6">   Class  </th>
                        <th scope="col" class="py-3 px-6">   Date Given  </th>
                    </tr>
                </thead>
                <tbody>
                    {[...pagUsers].map((ga, i) => <GiveAwayItem giveAway={ga} />)}
                </tbody>
            </table>
        </div>
    );
}
function GiveAwayItem({ giveAway }) {
    return <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {giveAway.id}
        </th>
        <td>  {giveAway.amount}  </td>
        <td>  {giveAway.userPhone}  </td>
        <td>  {giveAway.userType}  </td>
        <td>  {giveAway.timeline}  </td>
    </tr>
}


export function Pages({ current, pageCount, handlePageChange, counts, handleOptChange, total }) {
    return (
        <div className='bg-gray-100 py-5 my-5'>
            <div className="block text-sm overflow-scroll p-2">
                {[...Array(Math.ceil(total / pageCount)).keys()].map((k, i) => <label className={` cursor-pointer rounded-lg px-3 p-5 bg-gray-100  ${k + 1 === current && " bg-green-400 text-white"}`} onClick={() => { handlePageChange(k) }} key={i}>{k + 1}</label>)}
            </div>
            <span>
                <select onChange={(e) => handleOptChange(e.target.value)} className="p-1 border rounded-lg m-1 w-20">
                    {counts.map(o => <option key={o} selected={o == pageCount} value={o}>{o}</option>)}
                </select>
            </span>
        </div>

    )
}